import moment from 'moment'

export default {
  data() {
    return {
      filterQuery: '',
    }
  },
  timer: null,
  watch: {
    filters: {
      handler(value) {
        clearTimeout(this.timer)
        this.filterQuery = ''
        const queries = []
        // eslint-disable-next-line no-restricted-syntax
        for (const filter in value) {
          if (this.filters[filter] && this.filters[filter] !== '') {
            if (this.filters[filter] instanceof Date) {
              queries.push(`filter[${filter}]=${encodeURIComponent(moment(this.filters[filter]).format('YYYY-MM-DD'))}`)
            } else if (typeof this.filters[filter] === 'object') {
              if (this.filters[filter].value)queries.push(`filter[${filter}]=${encodeURIComponent(this.filters[filter].value)}`)
            } else {
              queries.push(`filter[${filter}]=${encodeURIComponent(this.filters[filter])}`)
            }
          }
        }
        if (queries.length > 0) this.filterQuery = queries.join('&')
        const that = this
        this.timer = setTimeout(() => {
          that.filterQueryUpdate()
        }, 500)
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    updateDateRange(filterKey, newDateRange) {
      if (newDateRange.startDate && newDateRange.endDate) {
        this.filters[filterKey] = {
          startDate: moment(newDateRange.startDate).toISOString(),
          endDate: moment(newDateRange.endDate).toISOString(),
        }
      } else {
        this.filters[filterKey] = {
          startDate: null,
          endDate: null,
        }
      }
    },
    buildFilterQuery() {
      const filterEntries = Object.entries(this.filters)
      const queryParts = filterEntries
        .filter(([value]) => {
          // Filter out empty or null values
          if (typeof value === 'object' && value.startDate && value.endDate) {
            return true
          }
          return value
        })
        .map(([key, value]) => {
          if (value === null || value === undefined || value === '') return ''

          if (typeof value === 'object') {
            if (!value.startDate || !value.endDate) return '' // Ignore if missing dates
            return `&filter[${key}]=${value.startDate} to ${value.endDate}`
          }
          return `&filter[${key}]=${value}`
        })

      return queryParts.join('')
    },
  },
}
